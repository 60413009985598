<template>
  <div class="auftragsliste">
    <h1>Auftragsliste</h1>
    <p>
      Hier kannst du alle Aufträge deiner Firma im CSV-Format herunterladen. Die Datei kannst du in Microsoft Excel öffnen.
    </p>
    <!--
    <b-dropdown split text="Download Exceldatei" variant="primary" :disabled="downloadRunning === true" v-on:click="downloadAuftragsListeXLS">
      <b-dropdown-item v-on:click="downloadAuftragsListeXLS">Download Exceldatei</b-dropdown-item>
      <b-dropdown-item v-on:click="downloadAuftragsListeCSV">Download CSV-Datei</b-dropdown-item>
    </b-dropdown>
    -->
    <b-button variant="primary" v-on:click.prevent.stop="downloadAuftragsListeCSV">Download CSV-Datei</b-button>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AuftragService from '../../../common/api/private/AuftragService'
  import FileDownload from 'js-file-download'

  import { DropdownPlugin, ButtonPlugin } from 'bootstrap-vue'
  Vue.use(DropdownPlugin)
  Vue.use(ButtonPlugin)

  export default {
    name: 'AuftragsListe',
    data() {
      return {
        downloadRunning: false
      }
    },
    methods: {
      downloadAuftragsListeXLS() {
        if (this.downloadRunning === false) {
          this.downloadRunning = true
          AuftragService.getXlsList().then(response => {
            if(response.status === 204) {
              alert('Es sind keine Aufträge für den Download als CSV verfügbar.')
            } else {
              FileDownload(response.data, 'fairtriebs-champions-energie-auftraege.xls');
            }
          }).catch(() => {
            alert("Fehlgeschlagen")
          }).finally(() => {
            this.downloadRunning = false
          })
        }
      },
      downloadAuftragsListeCSV() {
        if (this.downloadRunning === false) {
          this.downloadRunning = true
          AuftragService.getCsvList().then(response => {
            if(response.status === 204) {
              alert('Es sind keine Aufträge für den Download als CSV verfügbar.')
            } else {
              FileDownload(response.data, 'fairtriebs-champions-energie-auftraege.csv');
            }
          }).catch(() => {
            alert("Fehlgeschlagen")
          }).finally(() => {
            this.downloadRunning = false
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
